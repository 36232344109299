<template>
	<div
		class="select search select--c"
		:class="{
			active: isOpen,
			'select--onboarding': isOnboarding,
			'search-user': userStyle,
			'select--error': isError && isOnboarding,
			'select--lg select--simple': isCourses
		}"
	>
		<select>
			<option
				v-for="(opt, index) in ownOptions"
				:key="index"
				:selected="isSelected(index)"
			>
				{{ textOption(opt, ownOptions, index) }}
			</option>
		</select>
		<div
			class="select-button"
			@click="openDropdown"
		>
			<label
				class="field-label"
			>
				<input
					ref="inpt"
					v-model.trim="current.text"
					type="text"
					:class="addClass"
					:style="addStyle"
					:placeholder="name"
					:readonly="(!autosearch &&
						!autosearchCity &&
						!autosearchRegion &&
						!autosearchEducation &&
						!autosearchVacancyCity &&
						!autosearchCompany) &&
						(isContestPersonalChoice && !isOpenWriting)"
					@input="searching"
					@keydown.stop="ctrlDropdown"
					@change="writeOption(current.text)"
				>
				<div
					v-if="!isOnboarding && !isCourses"
					class="label"
					@click="openDropdown"
				>
					{{ name }}
				</div>
				<span
					v-if="isNotExist || (isError && !isOnboarding) && (isError && !isRole)"
					class="help-block form-error"
				>
					{{
						isNotExist
							? `${current.text} не найден`
							: isError
								? "Это обязательное поле"
								: ""
					}}
				</span>
				<div
					v-if="isError && isOnboarding && !isCity && !autosearchNomination"
					class="error-field"
				>
					Выбери учебное заведение
				</div>
				<div
					v-if="isError && isOnboarding && isCity"
					class="error-field"
				>
					Выберите город
				</div>
				<div
					v-if="isError && isRole"
					class="field-simple__error"
				>
					Выберите профиль
				</div>
				<div
					v-if="isError && autosearchNomination && !isContestTask"
					class="field-simple__error"
				>
					Выберите номинацию
				</div>
				<div
					v-if="isError && autosearchNomination && isContestTask"
					class="field-simple__error"
				>
					Обязательно для заполнения
				</div>
			</label>
			<div
				v-if="
					!autosearchCity &&
						!autosearchRegion &&
						!autosearchEducation &&
						!autosearchVacancyCity &&
						!autosearchCompany
				"
				class="select-arrow"
				:style="styleOptions"
			/>
		</div>
		<div
			v-if="isOpen && ownOptions && ownOptions.length"
			ref="dropdown"
			class="select-dropdown"
			@scroll="scrollDropdown"
		>
			<div
				v-for="(opt, index) in ownOptions"
				:key="index"
				class="select-option"
				:class="{
					'active':
						isSelected(index) &&
						!autosearchEduBackground &&
						!autosearchYears,
					'pseudo-active': isPseudoSelected(index),
					'uppercased': autosearchEducation,
					'lowercased': autosearchEducationOnboarding,
				}"
				@click.stop="selectOption(index)"
			>
				{{ textOption(opt, ownOptions, index) }}
				<span
					v-if="autosearchNomination && opt.status"
					class="select-tag"
					:class="{
						'select-tag--info': opt.status === 1 || opt.status === 2 || opt.status === 4 || opt.status === 8,
						'select-tag--success': opt.status === 9 || opt.status === 7,
						'select-tag--error': opt.status === 5,
						'select-tag--attention': opt.status === 3,
						'select-tag--neutral': opt.status === 6 || opt.status === 10 || opt.status === -1,

					}"
				>{{ requestStatuses.find((item) => item.value === opt.status).label }}</span>
			</div>
		</div>
	</div>
</template>

<script>

import Re from '@/api/recruiter';
import Tb from '@/api/teachbase';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@/assets/scripts/common';

export default {
	props: {
		options: {
			type: Array,
			default: function() {
				return new Array();
			}
		},
		defaultData: {
            type: String || Number,
            default: null
        },
		name: {
            type: String,
            default: ''
        },
		isClear: {
			type: Boolean,
			default: false
		},
		isOnboarding: {
			type: Boolean,
			default: false
		},
		isCity: {
			type: Boolean,
			default: false
		},
        isCourses: {
			type: Boolean,
			default: false
		},
		chooseRole: {
			type: Boolean,
			default: false
		},
		autosearch: {
			type: Boolean,
			default: false
		},
		autosearchCity: {
			type: Boolean,
			default: false
		},
		autosearchRegion: {
			type: Boolean,
			default: false
		},
		regionName: {
			type: String,
			default: ''
		},
		autosearchVacancyCity: {
			type: Boolean,
			default: false
		},
		autosearchCompany: {
			type: Boolean,
			default: false
		},
		autosearchReCompany: {
			type: Boolean,
			default: false
		},
		autosearchEducation: {
			type: Boolean,
			default: false
		},
        autosearchEducationOnboarding: {
			type: Boolean,
			default: false
		},
		autosearchEduBackground: {
			type: Boolean,
			default: false
		},
		roleSelect: {
			type: Boolean,
			default: false
		},
		autosearchYears: {
			type: Boolean,
			default: false
		},
        autosearchNomination: {
            type: Boolean,
            default: false
        },
		isContestTask: {
			type: Boolean,
			default: false
		},
		isError: {
			type: Boolean,
			default: false
		},
		isRole: {
			type: Boolean,
			default: false
		},
		userStyle: {
			type: Boolean,
			default: false
		},
        isContestPersonalChoice: {
            type: Boolean,
            default: false
        }
	},
	data: () => ({
        isOpenWriting: false,
		years: [],
		isOpen: false,
		isNotExist: false,
		ownOptions: null,
		page: 1,
		current: {
			id: 0,
			text: ''
		},
		pseudoCurrent: {
			id: 0,
			text: ''
		}
	}),
	computed: {
		...mapGetters('cnst', {
			EDU: 'getEducation',
            requestStatuses: 'getRequestStatuses'
		}),
		isRecruiter() {
			return this.$store.getters['user/isRecruiter'];
		},
		addClass() {
            if (this.autosearchEducationOnboarding) return 'select-value field lowercased'
            if (this.isRole && this.isError) return 'select-value field-simple__value field-simple__value--error'
            if (this.isRole && !this.isError) return 'select-value field-simple__value'
			return this.autosearchCity ||
				this.autosearchRegion ||
				this.autosearchEducation ||
				this.autosearchVacancyCity ||
				this.chooseRole ||
				this.autosearchCompany
				? 'select-value field'
				: 'select-value field field-select';
		},
		addStyle() {
			return this.autosearchCity ||
				this.autosearchRegion ||
				this.autosearchEducation ||
				this.autosearchVacancyCity ||
				this.autosearchCompany
				? 'cursor: text'
				: 'cursor: pointer';
		},
		styleOptions() {
			return this.chooseRole ? '' :
                this.isCourses ? this.$options.filters.bgrImageCover('/img/icons/icon__triangle-down--gray.svg') :this.$options.filters.bgrImageCover('/img/icons/icon-arrow-select.svg');
		}
	},
	watch: {
		isError: function(newV) {
			if (newV) {
				this.isOpen = false;
				this.page = 1;
				this.current.id = 0;
				this.current.text = '';
			}
		},
		isClear: function(newV) {
			if (newV) {
				this.isOpen = false;
				this.page = 1;
				this.current.id = 0;
				this.current.text = '';
				this.$emit('cleared');
			}
		},
        defaultData: function(newV, oldV) {
            if (newV) {
                this.current.text = newV
            }
        }
	},

	created() {
		this.ownOptions = this.options;

        if (this.isContestPersonalChoice) {
            let isPersonalChoice = this.ownOptions.find((item) => item === this.defaultData);
            this.isOpenWriting = !isPersonalChoice;
            this.ownOptions.push('Другое')
        }
		if (this.autosearchReCompany) {
			Re.getAllReCompanies().then(response => {
				if (this.isRecruiter) {
					this.ownOptions = [];
					response.data.forEach(x =>
						Tb.getCompany(x.id)
							.then(response => response.data)
							.then(result =>
								this.ownOptions.push({
									id: x.id,
									name: result.company_real_name
								})
							)
					);
				} else {
					this.ownOptions = response.data;
				}
			});
		}

		if (this.autosearchEduBackground) {
			this.getStudyPlace().then(res => {
				this.ownOptions = [];
				res.forEach(item => {
					if (item.primary_education) {
						this.current.text = item.name ? item.name : '';
						this.ownOptions = this.EDU.map(x => x);
					}
				});
			});
		}

		if (this.autosearchYears) {
			this.getStudyPlace().then(res => {
				this.ownOptions = [];
				res.forEach(item => {
					if (item.primary_education) {
						this.current.text = item.graduation_year;
					}
				});
				let start = 1980;
				while (start <= new Date().getFullYear() + 6) {
					this.years.push(start++);
				}
				this.ownOptions = this.years;
			});
		}

        if (this.isContestTask) this.ownOptions = this.options.map(x => x);

		document.addEventListener('click', event => {
			if (!event.target.closest('.select--c')) this.isOpen = false;
		});
	},
	mounted() {
		if (this.defaultData) {
			this.current.text = this.defaultData;
			this.current.id = this.options.findIndex(val => {
				if (this.autosearchReCompany) {
					return val.name.toLowerCase() === this.defaultData.toLowerCase();
				} else {
					if (
						typeof this.defaultData === 'string' &&
						val.toLowerCase() === this.defaultData.toLowerCase()
					)
						return true;
					else return typeof this.defaultData === 'number' &&
                        val === this.defaultData;
				}
			});
		}

		if (
			this.autosearchCity ||
			this.autosearchRegion ||
			this.autosearchEducation ||
			this.autosearchVacancyCity ||
			this.autosearchCompany
		) {
			this.callDebounceWrapper = debounce(() => {
				let inputTime = +new Date();
				setTimeout(() => {
					let callTime = +new Date();
					if (callTime >= inputTime + 1000) {
						this.page = 1;
						this.autosearchCity &&
							this.getGlobalCities({
								q: this.current.text,
                                region: this.regionName
							}).then(results => {
                                this.isOpen = true;
                                this.ownOptions = results;
							});

						this.autosearchRegion &&
							this.getGlobalRegions({
								q: this.current.text
							}).then(results => {
								if (results.length) {
									this.isOpen = true;
									this.ownOptions = results.map(x => x.region);
								} else {
									this.ownOptions = results;
								}
							});

						this.autosearchVacancyCity &&
							this.getVacancyCities({
								search: this.current.text,
								page: this.page
							}).then(results => {
								if (results.length) {
									this.isOpen = true;
									this.ownOptions = results.map(x => x.name);
								} else {
									this.ownOptions = results;
								}
							});

						this.autosearchCompany &&
							this.getAllCompanies({
								company_name: this.current.text,
								page: this.page
							}).then(response => {
								if (response.count >= 1) {
									this.isOpen = true;
									this.isNotExist = false;
									this.ownOptions = response.results;
								} else {
									this.ownOptions = null;
									this.isNotExist = true;
									this.$emit(
										'is-not-exist',
										this.current.text
									);
								}
							});

						this.autosearchEducation &&
							this.getAllEducations({
								search: this.current.text.toUpperCase(),
								page: this.page
							}).then(results => {
								if (results.length) {
									this.isOpen = true;
									this.ownOptions = results.map(
										x => `${x.name}, ${x.city}`
									);
								} else {
									this.ownOptions = results;
								}
							});
					}
				}, 1000);
			}, 1000);
		}
	},
	methods: {
		...mapActions('cmn', [
			'getVacancyCities',
			'getAllEducations',
			'getAllCompanies'
		]),
		...mapActions('ms', [
			'getGlobalCities',
            'getGlobalRegions'
		]),
		...mapActions('user', ['getStudyPlace']),
		// ...mapActions('smpl', [
		// 		'getEventSample',
		// ]),
		openDropdown() {
			if (
				!this.autosearchCity &&
				!this.autosearchRegion &&
				!this.autosearchEducation &&
				!this.autosearchVacancyCity &&
				!this.autosearchCompany
			) {
				this.isOpen = !this.isOpen;
			}
		},
		scrollDropdown() {
			if (
				this.autosearchEducation ||
				this.autosearchVacancyCity ||
				this.autosearchCompany ||
				this.autosearchEduBackground ||
				this.autosearchYears
			) {
				let height = this.$refs.dropdown.getBoundingClientRect().height,
					scrollHeight = this.$refs.dropdown.scrollHeight,
					scrollTop = this.$refs.dropdown.scrollTop;

				if (scrollHeight === height + scrollTop) {
					debounce(() => {
						this.autosearchVacancyCity &&
							this.getVacancyCities({
								search: this.current.text,
								page: ++this.page
							}).then(results => {
								if (results.length) {
									this.isOpen = true;
									results.forEach(city =>
										this.ownOptions.push(city.name)
									);
								}
							});
						this.autosearchCompany &&
							this.getAllCompanies({
								company_name: this.current.text,
								page: ++this.page
							}).then(results => {
								if (results.length) {
									this.isOpen = true;
									results.forEach(city =>
										this.ownOptions.push(city.name)
									);
								}
							});
						this.autosearchEducation &&
							this.getAllEducations({
								search: this.current.text.toUpperCase(),
								page: ++this.page
							}).then(results => {
								if (results.length) {
									this.isOpen = true;
									results.forEach(edu =>
										this.ownOptions.push(
											`${edu.name}, ${edu.city}`
										)
									);
								}
							});
					}, 1000)();
				}
			}
		},
        writeOption(value) {
            if (this.autosearchNomination && this.isContestTask) {
                localStorage.removeItem('selectedPersonalChoice')
                this.current.text = value;
                this.$emit('selected', { text: this.current.text, name: this.name });
                localStorage.setItem('selectedPersonalChoice', value)
            }
        },
		selectOption(index) {
			this.$refs.inpt.focus();
			this.current.id = index;
			if (this.autosearchCompany || this.autosearchReCompany) {
				this.current.text = this.ownOptions[index].name;
				this.$emit('selected', this.ownOptions[index]);
			} else if (this.autosearchEducation) {
				this.current.text = this.ownOptions[index].toUpperCase();
				this.$emit('selected', this.ownOptions[index]);
			} else if (this.autosearchNomination && !this.isContestTask) {
                this.current.text = this.ownOptions[index].name;
                this.$emit('selected', this.ownOptions[index]);
            } else if (this.autosearchNomination && this.isContestTask) {
                this.current.text = this.ownOptions[index];
                if (this.ownOptions[index] === 'Другое') {
                    this.isOpenWriting = true;
                    if (localStorage.getItem('selectedPersonalChoice')) {
                        this.current.text = localStorage.getItem('selectedPersonalChoice')
                    } else this.current.text = 'Напишите свой вариант';
                } else this.isOpenWriting = false
                this.$emit('selected', { text: this.current.text, name: this.name });
            } else if (this.autosearchCity) {
                this.current.text = this.ownOptions[index].city;
                this.$emit('selected', this.ownOptions[index].city);
                this.$emit('defaultRegion', this.ownOptions[index].region);
            } else if (this.isContestTask && !this.autosearchCity) {
                this.current.text = this.ownOptions[index];
                this.$emit('selected', { text: this.current.text, name: this.name });
            }else if (this.autosearchRegion) {
				this.current.text = this.ownOptions[index];
				this.$emit('selected', this.current.text);
				this.$emit('defaultCity', '');
			} else {
				this.current.text = this.ownOptions[index];
				this.$emit('selected', this.current.text);
			}
			this.isOpen = false;
		},
		textOption(value, options, index) {
			if (this.autosearchCompany || this.autosearchReCompany) {
				return options[index].name;
			} else if (this.autosearchCity) {
                return options[index].city;
            } else if (options[index].text) {
				return options[index].text;
			} else if (this.autosearchEduBackground || (this.isContestTask && !this.autosearchCity)) {
				return options[index];
			} else if (this.autosearchNomination) {
                return options[index].name;
            } else {
				return value;
			}
		},
		isSelected(index) {
			if (this.autosearchReCompany) {
				if (
					this.ownOptions.some(
						opt => opt.name === this.current.text
					) &&
					this.current.id === index
				)
					return true;
			} else if (
				this.ownOptions.some(opt => opt === this.current.text) &&
				this.current.id === index
			)
				return true;
			else return false;
		},
		isPseudoSelected(index) {
			if (this.pseudoCurrent.text && this.pseudoCurrent.id === index)
				return true;
			else return false;
		},
		searching() {
			if (this.autosearch) {
				this.isOpen = true;
				this.ownOptions = this.options.filter(opt => {
					if (opt.includes(this.current.text)) return true;
					else false;
				});
			} else if (
				this.autosearchCity ||
				this.autosearchRegion ||
				this.autosearchEducation ||
				this.autosearchVacancyCity ||
				this.autosearchCompany
			) {
				if (this.autosearchEducation && !this.autosearchEducationOnboarding)
					this.current.text = this.current.text.toUpperCase();
				this.$emit(
					'selected',
					this.autosearchEducation && !this.autosearchEducationOnboarding
						? this.current.text.toUpperCase()
						: this.current.text
				);
				this.callDebounceWrapper();
			} else return;
		},
		ctrlDropdown(event) {
			if (event.keyCode === 40 && this.isOpen) {
				// Arrow-down
				if (this.pseudoCurrent.text) {
					if (this.pseudoCurrent.id < this.ownOptions.length)
						this.pseudoCurrent.text = this.ownOptions[
							++this.pseudoCurrent.id
						];
					else {
						this.pseudoCurrent.id = 0;
						this.pseudoCurrent.text = this.ownOptions[
							this.pseudoCurrent.id
						];
					}
				} else this.pseudoCurrent.text = this.ownOptions[0];
			} else if (event.keyCode === 38 && this.isOpen) {
				// Arrow-up
				if (this.pseudoCurrent.text) {
					if (this.pseudoCurrent.id > 0)
						this.pseudoCurrent.text = this.ownOptions[
							--this.pseudoCurrent.id
						];
					else {
						this.pseudoCurrent.id = this.ownOptions.length - 1;
						this.pseudoCurrent.text = this.ownOptions[
							this.pseudoCurrent.id
						];
					}
				} else
					this.pseudoCurrent.text = this.ownOptions[
						this.ownOptions.length - 1
					];
			} else if (event.keyCode === 27 && this.isOpen) {
				// Esc
				this.isOpen = false;
			} else if (event.keyCode === 13) {
				// Enter
				event.preventDefault();
				if (this.isOpen) {
					this.selectOption(this.pseudoCurrent.id);
					// if (this.current.id) {
					//   this.current.text = this.ownOptions[this.current.id]
					// } else {
					// this.current.text = this.ownOptions[this.current.id]

					// this.current.text = this.pseudoCurrent.text
					// this.current.id = this.pseudoCurrent.id
					// }
					// this.isOpen = false;
					this.$refs.inpt.focus();
				} else this.isOpen = true;
			} else if (event.keyCode === 9) {
				// Tab
				// Восстановление стандартного поведения перехода на следующее поле ввода формы
				// event.target.closest('form').elements.forEach((elm, index, list) => {
				//   if (elm === this.$refs.inpt && list.length > index + 1) list[index + 1].focus();
				//   this.isOpen = false;
				// })
			}
		}
	}
};
</script>

<style lang="less" scoped>
// .select--onboarding {
// 	input {
// 		text-transform: lowercase !important;
// 	}
// 	&::first-letter {
// 		text-transform: uppercase !important;
// 	}
// }
.uppercased {
	text-transform: uppercase;
}
.lowercased {
	text-transform: none;
}
.search-user.select .select-value.field-select {
	padding-right: 25px;
}
.search-user.select .select-value {
	border-bottom-color: transparent;
	height: 18px;
	font-size: 13px;
}

@media (max-width: 767px) {
	.select .select-option {
		padding: 5px 15px;
	}
}
</style>
