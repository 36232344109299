var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select search select--c",class:{
		active: _vm.isOpen,
		'select--onboarding': _vm.isOnboarding,
		'search-user': _vm.userStyle,
		'select--error': _vm.isError && _vm.isOnboarding,
		'select--lg select--simple': _vm.isCourses
	}},[_c('select',_vm._l((_vm.ownOptions),function(opt,index){return _c('option',{key:index,domProps:{"selected":_vm.isSelected(index)}},[_vm._v(" "+_vm._s(_vm.textOption(opt, _vm.ownOptions, index))+" ")])}),0),_c('div',{staticClass:"select-button",on:{"click":_vm.openDropdown}},[_c('label',{staticClass:"field-label"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.current.text),expression:"current.text",modifiers:{"trim":true}}],ref:"inpt",class:_vm.addClass,style:(_vm.addStyle),attrs:{"type":"text","placeholder":_vm.name,"readonly":(!_vm.autosearch &&
					!_vm.autosearchCity &&
					!_vm.autosearchRegion &&
					!_vm.autosearchEducation &&
					!_vm.autosearchVacancyCity &&
					!_vm.autosearchCompany) &&
					(_vm.isContestPersonalChoice && !_vm.isOpenWriting)},domProps:{"value":(_vm.current.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.current, "text", $event.target.value.trim())},_vm.searching],"keydown":function($event){$event.stopPropagation();return _vm.ctrlDropdown.apply(null, arguments)},"change":function($event){return _vm.writeOption(_vm.current.text)},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.isOnboarding && !_vm.isCourses)?_c('div',{staticClass:"label",on:{"click":_vm.openDropdown}},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),(_vm.isNotExist || (_vm.isError && !_vm.isOnboarding) && (_vm.isError && !_vm.isRole))?_c('span',{staticClass:"help-block form-error"},[_vm._v(" "+_vm._s(_vm.isNotExist ? `${_vm.current.text} не найден` : _vm.isError ? "Это обязательное поле" : "")+" ")]):_vm._e(),(_vm.isError && _vm.isOnboarding && !_vm.isCity && !_vm.autosearchNomination)?_c('div',{staticClass:"error-field"},[_vm._v(" Выбери учебное заведение ")]):_vm._e(),(_vm.isError && _vm.isOnboarding && _vm.isCity)?_c('div',{staticClass:"error-field"},[_vm._v(" Выберите город ")]):_vm._e(),(_vm.isError && _vm.isRole)?_c('div',{staticClass:"field-simple__error"},[_vm._v(" Выберите профиль ")]):_vm._e(),(_vm.isError && _vm.autosearchNomination && !_vm.isContestTask)?_c('div',{staticClass:"field-simple__error"},[_vm._v(" Выберите номинацию ")]):_vm._e(),(_vm.isError && _vm.autosearchNomination && _vm.isContestTask)?_c('div',{staticClass:"field-simple__error"},[_vm._v(" Обязательно для заполнения ")]):_vm._e()]),(
				!_vm.autosearchCity &&
					!_vm.autosearchRegion &&
					!_vm.autosearchEducation &&
					!_vm.autosearchVacancyCity &&
					!_vm.autosearchCompany
			)?_c('div',{staticClass:"select-arrow",style:(_vm.styleOptions)}):_vm._e()]),(_vm.isOpen && _vm.ownOptions && _vm.ownOptions.length)?_c('div',{ref:"dropdown",staticClass:"select-dropdown",on:{"scroll":_vm.scrollDropdown}},_vm._l((_vm.ownOptions),function(opt,index){return _c('div',{key:index,staticClass:"select-option",class:{
				'active':
					_vm.isSelected(index) &&
					!_vm.autosearchEduBackground &&
					!_vm.autosearchYears,
				'pseudo-active': _vm.isPseudoSelected(index),
				'uppercased': _vm.autosearchEducation,
				'lowercased': _vm.autosearchEducationOnboarding,
			},on:{"click":function($event){$event.stopPropagation();return _vm.selectOption(index)}}},[_vm._v(" "+_vm._s(_vm.textOption(opt, _vm.ownOptions, index))+" "),(_vm.autosearchNomination && opt.status)?_c('span',{staticClass:"select-tag",class:{
					'select-tag--info': opt.status === 1 || opt.status === 2 || opt.status === 4 || opt.status === 8,
					'select-tag--success': opt.status === 9 || opt.status === 7,
					'select-tag--error': opt.status === 5,
					'select-tag--attention': opt.status === 3,
					'select-tag--neutral': opt.status === 6 || opt.status === 10 || opt.status === -1,

				}},[_vm._v(_vm._s(_vm.requestStatuses.find((item) => item.value === opt.status).label))]):_vm._e()])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }