import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        'Content-type': 'application/json'
    },
})

const microService = axios.create({
    baseURL: 'https://geo.api.lift-bf.ru',
    headers: {
        'Content-type': 'application/json'
    },
})

const contestService = axios.create({
    baseURL:  (window.location.hostname === 'stage.lift.teachbasetest.ru') ?
        'https://contest.stage.lift.teachbasetest.ru' :
            (window.location.hostname === 'lift-bf.ru') ?
                'https://contest.api.lift-bf.ru' : 'https://contest.lift.teachbasetest.ru',
    headers: {
        'Content-type': 'application/json'
    }
})

HTTP.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        localStorage.removeItem('userToken');
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('userID');
        localStorage.removeItem('userRole');
        sessionStorage.removeItem('manual_close');
    }

    return Promise.reject(error);
});

export { microService, HTTP, contestService };

export function getToken() {
    return localStorage.getItem('userToken')
}


export function getJWTToken() {
    return localStorage.getItem('jwtToken')
}
